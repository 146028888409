<template>
    <div>
        <div v-bind:class="{ 'reviewing': reviewing }">
            <div class="row">
                <div class="col-md-6">
                    <h5>{{ title }}</h5>
                </div>
                <div class="col-md-6 text-right">
                    <button type="button" class="btn btn-primary" v-on:click="edit">{{ buttonName }}</button>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    {{ text_area_value }}
                </div>
            </div>
        </div>

        <div v-bind:class="{ 'editing': editing }">
            <form method="post" @submit.prevent="submit" v-bind:action="apiUrl">
                <alert-popup v-if="success" alert-type="success" alert-message="Field updated"></alert-popup>
                <input type="hidden" :value="csrfToken" name="_token"/>
                <div class="form-row">
                    <div class="col-md-6">
                        <h5>{{ title }}</h5>
                    </div>
                    <div class="col-md-6 text-right">
                        <input type="submit" class="btn btn-primary" value="Save">
                    </div>
                </div>
                <div class="form-row">
                    <v-textarea
                            outlined
                            auto-grow
                            filled
                            v-model="text_area_value"
                            color="deep-purple"
                            v-bind:name="fieldName"
                            height="100px"
                    ></v-textarea>
                    <div v-if="errors && errors.message" class="text-danger">{{ errors.term_session_id[0] }}</div>
                </div>
            </form>
        </div>
    </div>


</template>

<script>
    import axios from 'axios';

    export default {
        props: ['fieldName', 'apiUrl', 'title', 'currentValue', 'identifyingId', 'buttonName'],
        mounted() {
            this.csrfToken = document.querySelector('meta[name="csrf-token"]').content
        },
        data() {
            return {
                text_area_value: this.currentValue,
                editing: true,
                reviewing: false,
                errors: {},
                success: false,
                loaded: true,
                csrfToken: null,
            }
        },
        methods: {
            edit(){
                this.editing = !this.editing;
                this.reviewing = !this.reviewing;
            },
            submit() {
                if (this.loaded) {
                    this.loaded = false;
                    this.success = false;
                    this.errors = {};
                    axios.post(this.apiUrl, {
                        csrfToken: this.csrfToken,
                        identifyingId: this.identifyingId,
                        value: this.text_area_value
                    }).then(response => {
                        this.fields = {}; //Clear input fields.
                        this.loaded = true;
                        this.success = true;
                        this.edit();
                        window.location.reload();
                    }).catch(error => {
                        this.loaded = true;
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors || {};
                        }
                    });
                }

            },
        }
    }
</script>

<style>
    .editing{
        display: none;
    }
    .reviewing{
        display: none;
    }
</style>