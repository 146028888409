<template>
    <div>
        <alert-popup v-if="showMsg" :alert-type="alertType" :alert-message="msg"></alert-popup>

        <v-container ma-0 pa-0 grid-list-md text-xs-center fill-height v-if="!active">
            <v-layout row wrap>
                <v-flex xs12>
                    <v-btn @click="active = true">Add a Student Write-in</v-btn>
                </v-flex>
            </v-layout>
        </v-container>

        <v-container ma-0 pa-0 grid-list-md text-xs-right fill-height v-if="active">
            <v-layout row wrap>
                <v-flex xs12>
                    <v-btn class="mx-2" fab small color="primary" options="{'elevation':1}" @click="active = false">
                        <v-icon class="text-dark">close</v-icon>
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-container>

        <v-container ma-0 pa-0 grid-list-md text-xs-center fill-height v-if="active">
            <v-layout row wrap>
                <v-flex xs12>
                    <v-select
                            v-model="periodToSchedule"
                            :items="classPeriods"
                            item-text="label"
                            item-value="id"
                            placeholder="Select class period"
                            solo
                    ></v-select>
                </v-flex>

                <v-flex xs12 sm6 m6>
                    <v-text-field
                            placeholder="Student First Name"
                            solo
                            v-model="studentFirstName"
                    ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                    <v-text-field
                            placeholder="Student Last Name"
                            solo
                            v-model="studentLastName"
                    ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 m6>
                    <v-text-field
                            placeholder="Parent Email"
                            solo
                            v-model="parentEmail"
                    ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                    <v-text-field
                            placeholder="Parent Phone"
                            solo
                            v-model="parentPhone"
                    ></v-text-field>
                </v-flex>

                <button class="btn btn-block btn-primary" @click="addWriteIn()">Add Write-in</button>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    import axios from 'axios';



    export default {
        props: ['locationId', 'classId'],
        data() {
            return {
                classPeriods: [],
                periodToSchedule: 0,
                studentFirstName: '',
                studentLastName: '',
                parentEmail: '',
                parentPhone: '',
                active: false,
                errors: {},
                showMsg: false,
                alertType: 'success',
                msg: '',
                loaded: true,
                csrfToken: null,
            }
        },
        mounted() {
            this.getTermClassPeriods();

            this.csrfToken = document.querySelector('meta[name="csrf-token"]').content;
        },
        methods: {
            addWriteIn() {
                if (this.loaded) {
                    this.loaded = false;
                    this.success = false;
                    this.errors = [];
                    if (!this.studentFirstName || !this.studentLastName) {
                        this.errors.student = 'Student name required.';
                    }
                    if (!this.parentEmail) {
                        this.errors.email = 'Parent email required.';
                    }
                    if (!this.parentPhone) {
                        this.errors.phone = 'Parent phone required.';
                    }

                    axios.post('/addWriteIn', {
                        csrfToken: this.csrfToken,
                        classPeriodId: this.periodToSchedule,
                        studentFirstName: this.studentFirstName,
                        studentLastName: this.studentLastName,
                        parentEmail: this.parentEmail,
                        parentPhone: this.parentPhone
                    }).then(response => {
                        if (response.data.success === true){
                            this.showMsg = true;
                            this.alertType = "success";
                            this.msg = response.data.msg;
                            window.location.reload();
                        }else{
                            this.showMsg = true;
                            this.alertType = "error";
                            this.msg = response.data.msg;
                        }
                    }).catch(error => {
                        this.loaded = true;
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors || {};
                        }
                    });
                }
            },
            getTermClassPeriods(){
                axios.post('/getTermClassPeriods', {
                    csrfToken: this.csrfToken,
                    classId: this.classId
                }).then(response => {
                    this.classPeriods = response.data;
                });
            }
        },
    }
</script>