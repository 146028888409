<template>
    <div>
        <div class="row text-right">
            <div class="col-md-12 subheading">
                <v-icon @click="previousWeek()">chevron_left</v-icon>
                {{ currentWeek.Mon }} - {{ currentWeek.Sun }}
                <v-icon @click="nextWeek()">chevron_right</v-icon>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <table class="table table-striped table-bordered table-hover">
                    <thead>
                    <tr>
                        <th>Class Category</th>
                        <th v-for="(day, dayName) in currentWeek">{{ dayName }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(category, category_id) in categories">
                        <td>{{ category.category }}</td>
                        <td v-for="(schedule, day) in category.days" :key="day">
                            <schedule-project v-bind:project-id="projectId" v-bind:category-id="category_id" v-bind:day-date="day" v-bind:location-id="locationId" v-bind:project-scheduled="schedule.scheduled" v-if="schedule.available"></schedule-project>
                            <p class="m-0 p-0" v-if="schedule.otherProjects.length > 0" v-for="otherProject in schedule.otherProjects"><a :href="'/project/'+otherProject.id">{{ otherProject.code }}</a></p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';


    export default {
        props: ['projectId', 'locationId'],
        data() {
            return {
                currentWeek: [],
                categories: [],
                errors: {},
                success: false,
                loaded: true,
                csrfToken: null,
            }
        },
        mounted() {
            this.csrfToken = document.querySelector('meta[name="csrf-token"]').content;
            this.getCurrentWeek();
        },
        methods: {
            getCurrentWeek() {
                axios.post('/getCurrentWeek', {
                    csrfToken: this.csrfToken
                }).then(response => {
                    this.currentWeek = response.data;
                    this.getCategories();
                })
            },
            previousWeek(){
                axios.post('/getPreviousWeek', {
                    csrfToken: this.csrfToken,
                    currentWeek: this.currentWeek
                }).then(response => {
                    this.currentWeek = response.data;
                    this.getCategories();
                })
            },
            nextWeek(){
                axios.post('/getNextWeek', {
                    csrfToken: this.csrfToken,
                    currentWeek: this.currentWeek
                }).then(response => {
                    this.currentWeek = response.data;
                    this.getCategories();
                })
            },
            getCategories() {
                axios.post('/getClassCategories', {
                    csrfToken: this.csrfToken,
                    currentWeek: this.currentWeek,
                    locationId: this.locationId,
                    projectId: this.projectId
                }).then(response => {
                    this.categories = response.data;
                })
            }
        },
    }
</script>