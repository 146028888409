<template>
    <div>
        <alert-popup v-if="success" alert-type="success" v-bind:alert-message="successMessage"></alert-popup>
        <v-checkbox
                v-model="scheduleProject"
                @click.native="toggleScheduled"
        ></v-checkbox>
    </div>
</template>

<script>
    import axios from 'axios';


    export default {
        props: ['categoryId','dayDate','locationId','projectId','projectScheduled'],
        data() {
            return {
                scheduleProject: this.projectScheduled,
                errors: {},
                success: false,
                successMessage: '',
                loaded: true,
                csrfToken: null,
            }
        },
        mounted() {
            this.csrfToken = document.querySelector('meta[name="csrf-token"]').content;
        },
        methods: {
            toggleScheduled() {
                this.success = false;
                this.errors = {};
                if ( this.scheduleProject === true ){
                    axios.post('/scheduleProject', {
                        csrfToken: this.csrfToken,
                        categoryId: this.categoryId,
                        dayDate: this.dayDate,
                        locationId: this.locationId,
                        projectId: this.projectId
                    }).then(response => {
                        this.fields = {}; //Clear input fields.
                        this.loaded = true;
                        this.success = true;
                        this.successMessage = "Project scheduled!";
                    }).catch(error => {
                        this.loaded = true;
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors || {};
                        }
                    });
                }else{
                    axios.post('/cancelProject', {
                        csrfToken: this.csrfToken,
                        categoryId: this.categoryId,
                        dayDate: this.dayDate,
                        locationId: this.locationId,
                        projectId: this.projectId
                    }).then(response => {
                        this.fields = {}; //Clear input fields.
                        this.loaded = true;
                        this.success = true;
                        this.successMessage = "Project canceled!";
                    }).catch(error => {
                        this.loaded = true;
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors || {};
                        }
                    });
                }

            }
        },
    }
</script>