<template>
    <div>
        <div v-if="!loaded">
            <div class="row text-center mt-4 mb-4">
                <div class="col-md-12">
                    <div class="spinner-grow text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>

        <v-progress-circular
            v-show="!loaded"
            indeterminate
            color="primary"
        ></v-progress-circular>
        <v-container
            v-show="loaded"
            ma-0
            pa-0
            grid-list-md
            text-xs-center
            fill-height
        >
            <alert-popup
                v-if="showMsg"
                :alert-type="alertType"
                :alert-message="message"
            ></alert-popup>

            <v-layout row wrap justify-center>
                <v-flex xs2 text-xs-left>
                    <v-select
                        :items="locationTypeOptions"
                        label="Change Type"
                        v-model="locationTypeInfo"
                        @change="updateLocationTypeInfo()"
                    ></v-select>
                </v-flex>
                <v-flex xs2 text-xs-left> </v-flex>
                <v-flex xs8 text-xs-left v-if="!summerOnly">
                    <v-select
                        :items="types"
                        label="Select a Day"
                        item-text="name"
                        solo
                        v-model="type"
                        return-object
                        @change="getClasses()"
                    ></v-select>
                </v-flex>
                <v-flex xs8 text-xs-left v-if="summerOnly">
                    <v-select
                        :items="summerWeeks"
                        label="Select a Week"
                        item-text="pretty_date"
                        solo
                        v-model="summerWeek"
                        return-object
                        @change="getSummerClasses()"
                    ></v-select>
                </v-flex>
            </v-layout>
        </v-container>
        <div class="col-md-12">
            <empty></empty>
        </div>
        <v-container
            v-show="loaded && !summerOnly"
            ma-0
            pa-0
            grid-list-md
            text-xs-center
            fill-height
        >
            <v-layout row wrap justify-center>
                <v-flex xs4 text-left>
                    <v-icon @click="getWeekInfo('backward')"
                        >chevron_left</v-icon
                    >
                    Previous week
                </v-flex>
                <v-flex xs4 text-center>
                    {{ currentSchoolWeek.startDay }} -
                    {{ currentSchoolWeek.endDay }}
                </v-flex>
                <v-flex xs4 text-right>
                    Next week
                    <v-icon @click="getWeekInfo('forward')"
                        >chevron_right</v-icon
                    >
                </v-flex>
            </v-layout>
        </v-container>
        <div v-show="loaded" class="row justify-content-center">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">{{ locationName }} Classes</div>

                    <div class="card-body">
                        <div>
                            <div v-for="(sClasses, day) in classes">
                                <div
                                    v-if="
                                        type.name === day ||
                                        type.name === 'All Days' ||
                                        type.displayType === 'weekly'
                                    "
                                >
                                    <h3>{{ day }}</h3>
                                    <div class="table-responsive">
                                        <table
                                            class="table table-bordered table-striped"
                                        >
                                            <thead>
                                                <tr>
                                                    <th style="width: 20%">
                                                        Time
                                                    </th>
                                                    <th style="width: 30%">
                                                        Class
                                                    </th>
                                                    <th
                                                        v-if="
                                                            sClasses.days
                                                                .length > 0
                                                        "
                                                        v-for="dayName in sClasses.days"
                                                    >
                                                        {{ dayName }}
                                                    </th>
                                                    <th
                                                        v-if="
                                                            type.displayType !==
                                                            'weekly'
                                                        "
                                                    >
                                                        Total Students
                                                    </th>
                                                    <th
                                                        v-if="
                                                            type.displayType !==
                                                            'weekly'
                                                        "
                                                    >
                                                        Purchases
                                                    </th>
                                                    <th
                                                        v-if="
                                                            type.displayType !==
                                                            'weekly'
                                                        "
                                                    >
                                                        Projects
                                                    </th>
                                                    <th
                                                        v-if="
                                                            type.displayType !==
                                                            'weekly'
                                                        "
                                                    >
                                                        Makeups
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="single in sClasses.classes"
                                                    v-bind:style="
                                                        single.attendanceFlag
                                                            ? 'background-color: #FEFDDF;'
                                                            : ''
                                                    "
                                                >
                                                    <td>
                                                        {{
                                                            single.start_time
                                                                | moment(
                                                                    "h:mm a"
                                                                )
                                                        }}
                                                        -
                                                        {{
                                                            single.end_time
                                                                | moment(
                                                                    "h:mm a"
                                                                )
                                                        }}
                                                    </td>
                                                    <td>
                                                        <span
                                                            v-if="
                                                                single.term_class_id
                                                            "
                                                            ><a
                                                                v-bind:href="
                                                                    '/class/' +
                                                                    single.term_class_id
                                                                "
                                                                >{{
                                                                    single.name
                                                                }}</a
                                                            ></span
                                                        >
                                                        <span
                                                            v-if="
                                                                !single.term_class_id
                                                            "
                                                            ><a
                                                                v-bind:href="
                                                                    '/class/' +
                                                                    single.id
                                                                "
                                                                >{{
                                                                    single.name
                                                                }}</a
                                                            ></span
                                                        >
                                                    </td>
                                                    <td
                                                        v-if="
                                                            type.displayType ===
                                                            'weekly'
                                                        "
                                                        v-for="day in single.days"
                                                    >
                                                        {{ day }}
                                                    </td>
                                                    <td
                                                        v-if="
                                                            type.displayType !==
                                                            'weekly'
                                                        "
                                                    >
                                                        {{
                                                            single.totalStudents
                                                        }}
                                                        <span
                                                            v-if="
                                                                single.midSessionRegistrationFlag
                                                            "
                                                            ><v-icon
                                                                >person_add</v-icon
                                                            ></span
                                                        >
                                                        <span
                                                            v-if="
                                                                single.writeInFlag
                                                            "
                                                            ><v-icon
                                                                >create</v-icon
                                                            ></span
                                                        >
                                                    </td>
                                                    <td
                                                        v-if="
                                                            type.displayType !==
                                                            'weekly'
                                                        "
                                                    >
                                                        {{ single.purchases }}
                                                    </td>
                                                    <td
                                                        v-if="
                                                            type.displayType !==
                                                            'weekly'
                                                        "
                                                    >
                                                        <p
                                                            v-for="project in single.projects"
                                                        >
                                                            {{ project }}
                                                        </p>
                                                    </td>
                                                    <td
                                                        v-if="
                                                            type.displayType !==
                                                            'weekly'
                                                        "
                                                    >
                                                        <p
                                                            v-for="makeup in single.makeups"
                                                        >
                                                            <a
                                                                :href="
                                                                    '/profile/' +
                                                                    makeup.studentId
                                                                "
                                                                >{{
                                                                    makeup.studentName
                                                                }}</a
                                                            >
                                                            -
                                                            <span
                                                                v-if="
                                                                    makeup.project
                                                                "
                                                                >{{
                                                                    makeup
                                                                        .project
                                                                        .code
                                                                }} </span
                                                            ><span
                                                                v-if="
                                                                    !makeup.project
                                                                "
                                                                >No
                                                                projects</span
                                                            >
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    props: ["locationName", "locationId"],
    mounted() {
        axios
            .post("/api/get_location", {
                locationId: this.locationId,
            })
            .then((response) => {
                this.location = response.data;
                this.locationInfo = response.data.info;
                this.locationTypeInfo = response.data.info.type;
            });

        this.getWeekInfo("none");

        axios
            .post("/api/get_types", {
                locationId: this.locationId,
            })
            .then((response) => {
                this.types = response.data;
                if (
                    this.types.length === 1 &&
                    this.types[0].name === "Summer Programs"
                ) {
                    this.summerOnly = true;
                } else {
                    this.types.unshift({ name: "All Days" });
                }

                // var date = new Date();
                // var today = date.getDay();
                this.type = this.types[0];

                // for (var i = 0, len = this.types.length; i < len; i++) {
                //     if ( this.types[i].order === today ){
                //         this.type = this.types[i];
                //     }
                // }

                this.getSavedWeek();
            });

        this.csrfToken = document.querySelector(
            'meta[name="csrf-token"]'
        ).content;
    },
    data() {
        return {
            location: null,
            locationTypeInfo: "",
            locationInfo: [],
            locationTypeOptions: ["", "outreach", "studio"],
            types: [],
            type: {
                id: 0,
            },
            classes: [],
            summerClasses: [],
            summerWeeks: [],
            summerWeek: {},
            summerOnly: false,
            currentSchoolWeek: { startDay: "", endDay: "" },
            previousSchoolWeek: { startDay: "", endDay: "" },
            nextSchoolWeek: { startDay: "", endDay: "" },
            errors: {},
            showMsg: false,
            alertType: "success",
            message: "",
            success: false,
            loaded: false,
            csrfToken: null,
        };
    },
    methods: {
        updateLocationTypeInfo() {
            // Handle the logic when the location type changes
            console.log("Location Type changed to:", this.locationTypeInfo);
            // You can trigger further actions here, like making an API request
            axios
                .post("/update_location_type", {
                    locationId: this.locationId,
                    newType: this.locationTypeInfo,
                })
                .then((response) => {
                    // Handle success response
                    console.log(
                        "Location type updated successfully:",
                        response.data
                    );

                    if (response.data.success === true) {
                        this.showMsg = true;
                        this.alertType = "success";
                        this.message = response.data.message;
                        // window.location.reload();
                    }
                })
                .catch((error) => {
                    // Handle error
                    console.log("Error updating location type:", error);
                });
        },

        getWeekInfo(action) {
            axios
                .post("/get_week_info", {
                    currentWeek: this.currentSchoolWeek,
                    previousWeek: this.previousSchoolWeek,
                    nextSchoolWeek: this.nextSchoolWeek,
                    action: action,
                    locationId: this.locationId,
                })
                .then((response) => {
                    if (response.data) {
                        this.currentSchoolWeek = response.data.currentWeek;
                        this.previousSchoolWeek = response.data.previousWeek;
                        this.nextSchoolWeek = response.data.nextWeek;

                        if (action === "forward" || action === "backward") {
                            this.getClasses();
                        }
                    }
                });
        },
        getSavedWeek() {
            axios.post("/get_saved_week", {}).then((response) => {
                if (response.data) {
                    this.summerWeek = response.data;
                }
                this.getSavedType();
            });
        },
        getSavedType() {
            axios
                .post("/get_saved_type", {})
                .then((response) => {
                    if (response.data.id > 0) {
                        this.type = response.data;
                    }
                    if (this.type.id) {
                        this.getSummerWeeks();
                        this.getClasses();
                    } else {
                        this.getClasses();
                    }
                })
                .catch((error) => {
                    this.loaded = true;
                    console.log(error);
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors || {};
                    }
                });
        },
        getClasses() {
            axios
                .post("/api/get_classes_by_type", {
                    locationId: this.locationId,
                    typeId: this.type.id,
                    week: this.currentSchoolWeek,
                })
                .then((response) => {
                    this.regularClasses = response.data;
                    this.determineDisplayType();
                    console.log("/api/get_classes_by_type", response.data);
                });

            if (this.type.id > 0) {
                axios
                    .post("/save_type", {
                        typeId: this.type.id,
                    })
                    .then((response) => {});
            }
        },
        getSummerClasses() {
            console.log("getting summer classes");
            console.log(this.summerWeek);
            axios
                .post("/api/get_summer_classes", {
                    locationId: this.locationId,
                    typeId: this.type.id,
                    week: this.summerWeek["url_slug"],
                })
                .then((response) => {
                    this.summerClasses = response.data;
                    this.determineDisplayType();
                    console.log("/api/get_classes_by_type", response.data);
                });

            if (this.summerWeek["url_slug"]) {
                axios
                    .post("/save_week", {
                        week: this.summerWeek,
                    })
                    .then((response) => {});
            }
        },
        getSummerWeeks() {
            axios
                .post("/api/get_weeks", {
                    locationId: this.locationId,
                    typeId: this.type.id,
                })
                .then((response) => {
                    this.summerWeeks = response.data;
                    if (this.summerWeek.length === 0) {
                        this.summerWeek = this.summerWeeks[0];
                    }

                    for (
                        var i = 0, len = this.summerWeeks.length;
                        i < len;
                        i++
                    ) {
                        if (
                            this.summerWeeks[i]["current_week"] &&
                            !this.summerWeek
                        ) {
                            this.summerWeek = this.summerWeeks[i];
                        }
                    }
                    this.getSummerClasses();
                });
        },
        determineDisplayType() {
            if (this.type.displayType === "weekly") {
                this.classes = this.summerClasses;
                this.summerOnly = true;
            } else {
                this.classes = this.regularClasses;
            }
            this.loaded = true;
        },
    },
};
</script>

<style>
.v-select > .v-input__control > .v-input__slot > .v-menu > div {
    top: 33px !important;
    left: 0 !important;
}
</style>
