<template>
    <div>
        <alert-popup v-if="success" alert-type="success" alert-message="Write-in deleted!"></alert-popup>
        <p class="m-0"><span class="badge badge-secondary">Write-in</span>
            {{ studentName }}
            <v-icon @click="deleteWriteIn()" size="18">delete</v-icon></p>
        <p class="m-0">{{ parentName }} : <a :href="'mailto:'+parentEmail">{{ parentEmail }}</a> | {{ parentPhone }}</p>
    </div>
</template>

<script>
    import axios from 'axios';


    export default {
        props: ['writeInId','studentName', 'parentName', 'parentEmail', 'parentPhone'],
        data() {
            return {
                errors: {},
                success: false,
                loaded: true,
                csrfToken: null,
            }
        },
        mounted() {
            this.csrfToken = document.querySelector('meta[name="csrf-token"]').content;
        },
        methods: {
            deleteWriteIn() {
                if ( confirm("Are you sure you want to delete this write-in?")) {
                    axios.post('/deleteWriteIn', {
                        csrfToken: this.csrfToken,
                        writeInId: this.writeInId,
                        purchaseId: this.purchaseId
                    }).then(response => {
                        this.fields = {}; //Clear input fields.
                        this.success = true;
                        window.location.reload();
                    }).catch(error => {
                        this.loaded = true;
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors || {};
                        }
                    });
                }
            }
        },
    }
</script>