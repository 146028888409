<template>
    <form method="post" @submit.prevent="submit" action="/markProductPrepared">
        <alert-popup v-if="success" alert-type="success" alert-message="Product marked as handed out!"></alert-popup>
        <input type="hidden" :value="csrfToken" name="_token"/>
        <v-text-field
                v-model="teacherInitials"
                label="Initials"
                :append-outer-icon="'send'"
                @click:append-outer="handOut"
                mask="AAA"
        ></v-text-field>
    </form>
</template>

<script>
    import axios from 'axios';


    export default {
        props: ['purchaseId'],
        data() {
            return {
                teacherInitials: null,
                errors: {},
                success: false,
                loaded: true,
                csrfToken: null,
            }
        },
        mounted() {
            this.csrfToken = document.querySelector('meta[name="csrf-token"]').content;
        },
        methods: {
            handOut() {
                if (this.loaded) {
                    this.loaded = false;
                    this.success = false;
                    this.errors = {};
                    axios.post('/markProductHandedOut', {
                        csrfToken: this.csrfToken,
                        teacherInitials: this.teacherInitials,
                        purchaseId: this.purchaseId
                    }).then(response => {
                        this.fields = {}; //Clear input fields.
                        this.loaded = true;
                        this.success = true;
                    }).catch(error => {
                        this.loaded = true;
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors || {};
                        }
                    });
                }
            }
        },
    }
</script>