<template>
    <div>
        <alert-popup v-if="success" alert-type="success" alert-message="Term session saved!"></alert-popup>
        <v-container ma-0 pa-0 grid-list-md text-xs-center fill-height>
            <v-layout row wrap justify-center>
                <v-flex xs12>
                    <v-select
                            v-model="viewClassId"
                            :items="sameDayClasses"
                            item-text="label"
                            item-value="id"
                            placeholder="View Another Class"
                            solo
                            @change="goToClass()"
                    ></v-select>
                </v-flex>
            </v-layout>
        </v-container>
        <div v-if="errors && errors.message" class="text-danger">{{ errors.term_session_id[0] }}</div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        props: ['classId','locationId'],
        mounted() {
            axios.post('/api/getClassesByDay', {
                'classId': this.classId,
                'locationId': this.locationId
            }).then(response => {
                console.log(response.data);
                this.sameDayClasses = response.data;
                this.loaded = true;
            });

            this.csrfToken = document.querySelector('meta[name="csrf-token"]').content
        },
        data() {
            return {
                viewClassId: 0,
                sameDayClasses: [],
                errors: {},
                success: false,
                loaded: true,
                csrfToken: null,
            }
        },
        methods: {
            goToClass() {
                if ( this.viewClassId ){
                    window.location = '/class/'+this.viewClassId;
                }
            },
        }
    }
</script>

<style>
    .v-select > .v-input__control > .v-input__slot > .v-menu > div{
        top: 33px !important;
        left: 0 !important;
    }
</style>