<template>
    <div>
        <v-radio-group v-model="radioGroup">
            <v-radio
                    v-for="n in 3"
                    :key="n"
                    :label="`Radio ${n}`"
                    :value="n"
                    name="name"
            ></v-radio>
        </v-radio-group>

        <alert-popup v-if="success" alert-type="success" alert-message="Project created!"></alert-popup>

        <v-container ma-0 pa-0 grid-list-md text-xs-center fill-height>
            <v-layout row wrap>
                <label>Project Name *</label>
                <v-flex xs12 sm6 md12>
                    <v-text-field
                            placeholder="Project will appear as this."
                            solo
                            v-model="name"
                    ></v-text-field>
                </v-flex>
                <div v-if="errors && errors.name" class="text-danger">{{ errors.name }}</div>

                <label>Project Code *</label>
                <v-flex xs12 sm6 md12>
                    <v-text-field
                            placeholder="Assign a code."
                            solo
                            v-model="code"
                    ></v-text-field>
                </v-flex>
                <div v-if="errors && errors.code" class="text-danger">{{ errors.code }}</div>

                <label>Project Description</label>
                <v-flex xs6 md12>
                    <v-textarea
                            solo
                            name="input-7-4"
                            placeholder="Describe the project"
                            v-model="description"
                    ></v-textarea>
                </v-flex>

                <button class="btn btn-block btn-primary" @click="createProject()">Create Project</button>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    import axios from 'axios';


    export default {
        props: ['locationId'],
        data() {
            return {
                radioGroup: 1,
                name: null,
                code: null,
                universal: false,
                description: null,
                errors: {},
                success: false,
                loaded: true,
                csrfToken: null,
            }
        },
        mounted() {
            this.csrfToken = document.querySelector('meta[name="csrf-token"]').content;

            if ( !this.locationId ){
                this.universal = true;
            }
        },
        methods: {
            createProject() {
                if (this.loaded) {
                    this.loaded = false;
                    this.success = false;
                    this.errors = [];
                    if (!this.name) {
                        this.errors.name = 'Name required.';
                    }
                    if (!this.code) {
                        this.errors.code = 'Project code required.';
                    }

                    axios.post('/createProject', {
                        csrfToken: this.csrfToken,
                        locationId: this.locationId,
                        universal: this.universal,
                        name: this.name,
                        code: this.code,
                        description: this.description
                    }).then(response => {
                        this.fields = {}; //Clear input fields.
                        this.loaded = true;
                        this.success = true;
                    }).catch(error => {
                        this.loaded = true;
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors || {};
                        }
                    });
                }
            }
        },
    }
</script>