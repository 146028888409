<template>
    <div>
    <alert-popup v-if="success" alert-type="success" alert-message="Class scheduled"></alert-popup>
        <h1>Student Makeup Form</h1>
        <p>This form allows you to schedule a makeup class for one that your student missed or will miss in the future. Makeups must be made up within 14 days of the missed class.</p>
        <p class="font-weight-bold">If you need to modify the date or time of the makeup class after it is scheduled, you must contact the studio manager.</p>
        <p>If students miss the scheduled makeup class without notifying their studio manager or info@marvegos.com at least one day in advance of the scheduled makeup, their ability to makeup the class is forfeited.</p>
        <v-app>
            <v-stepper v-model="e1">
                <v-stepper-header>
                    <v-stepper-step :complete="e1 > 1" step="1">Your Child's Information</v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="e1 > 2" step="2">Select Day to Make-up</v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="3">Select Make-up Class</v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-container  grid-list-md>
                            <v-layout row wrap>
                                <v-flex md12>
                                    <div>Please fill out the email you used to purchase the class along with your student's first name, last name and birthday.</div>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap v-if="errors.name || errors.birthday || errors.email || errors.notFound">
                                <v-flex md12>
                                    <div class="alert alert-danger" v-for="error in errors">{{ error }}</div>
                                </v-flex>
                            </v-layout>
                        </v-container>
                        <v-container  grid-list-md text-xs-center>
                        <v-layout row wrap>
                            <v-flex xs12 sm12>
                                <v-text-field
                                        v-model="parent.email"
                                        label="Your Email"
                                        filled
                                        :rules="[rules.required, rules.email]"
                                ></v-text-field>
                            </v-flex>
                        </v-layout>

                        <v-layout row wrap>
                            <v-flex xs12 sm6>
                                <v-text-field
                                        v-model="student.firstName"
                                        label="Child's First Name"
                                        filled
                                        :rules="[rules.required]"
                                ></v-text-field>
                            </v-flex>

                            <v-flex xs12 sm6>
                                <v-text-field
                                        v-model="student.lastName"
                                        label="Child's Last Name"
                                        filled
                                        :rules="[rules.required]"
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                        </v-container>

                        <v-btn
                                color="primary"
                                @click="getStudentClasses()"
                        >
                            Continue
                        </v-btn>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-container  grid-list-md>
                            <v-layout row wrap>
                                <v-flex md12>
                                    <div>Please select a class. Missed classes are classes your student has already missed. Scheduled classes are upcoming classes that you can select if you know your student will miss it.</div>
                                </v-flex>
                            </v-layout>
                        </v-container>
                        <v-alert
                                :value="true"
                                color="info"
                                icon="info"
                                outlined
                        >
                            Make-up days must be scheduled within 2 weeks of the missed class.
                        </v-alert>

                        <div class="row">
                            <div class="col-md-12">
                                <v-radio-group class="col-md-12" v-model="selectedClass" :mandatory="false">
                                <h3 v-if="missedClasses.length > 0">Missed Classes</h3>


                                <table v-if="missedClasses.length > 0" class="table table-striped table-bordered table-hover">
                                    <thead>
                                    <tr>
                                        <th style="width: 20%;">Day</th>
                                        <th style="width: 10%;">Date</th>
                                        <th style="width: 10%;">Time</th>
                                        <th style="width: 50%;">Class</th>
                                        <th style="width: 10%;">Select</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="item in missedClasses">
                                        <td>{{ item.date | moment("dddd") }}</td>
                                        <td>{{ item.date }}</td>
                                        <td>{{ item.time }}</td>
                                        <td>{{ item.name }}</td>
                                        <td><v-radio v-bind:value="item.id"></v-radio></td>
                                    </tr>
                                    </tbody>
                                </table>

                            <h3 v-if="scheduledClasses.length > 0">Not Attending Classes</h3>

                            <table class="table table-striped table-bordered table-hover" v-if="scheduledClasses.length > 0">
                                <thead>
                                <tr>
                                    <th style="width: 20%;">Day</th>
                                    <th style="width: 10%;">Date</th>
                                    <th style="width: 10%;">Time</th>
                                    <th style="width: 50%;">Class</th>
                                    <th style="width: 10%;">Select</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in scheduledClasses">
                                        <td>{{ item.date | moment("dddd") }}</td>
                                        <td>{{ item.date }}</td>
                                        <td>{{ item.time }}</td>
                                        <td>{{ item.name }}</td>
                                        <td><v-radio v-bind:value="item.id"></v-radio></td>
                                    </tr>
                                </tbody>
                            </table>
                        </v-radio-group>
                            </div>
                        </div>

                        <v-btn text @click="e1 = 1">Back</v-btn>

                        <v-btn
                                color="primary"
                                @click="getMakeupClasses()"
                        >
                            Continue
                        </v-btn>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <div v-if="!thankYou">
                            <v-alert
                                    :value="true"
                                    color="info"
                                    icon="info"
                                    outlined
                            >
                                If your child attends a class working on a different project, they will attend 15 minutes later than the class start time. If canceling, please let the instructor know in advance at {{ phoneNumber }}.
                            </v-alert>

                            <h4 class="mt-4">Please select a makeup class below.</h4>

                            <div v-if="makeupExactPossibilities.length > 0">
                                <div class="row">
                                    <div class="col-md-12">
                                        <v-radio-group class="col-md-12" v-model="classToSchedule" :mandatory="true">
                                            <table class="table table-striped table-bordered table-hover">
                                                <thead>
                                                <tr>
                                                    <th>Day</th>
                                                    <th>Date</th>
                                                    <th>Time</th>
                                                    <th>Class</th>
                                                    <th>Select</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="item in makeupExactPossibilities">
                                                    <td>{{ item.date | moment("dddd") }}</td>
                                                    <td>{{ item.date | moment("l") }}</td>
                                                    <td>{{ item.date | moment("h:mm a") }}</td>
                                                    <td>{{ item.name }}</td>
                                                    <td><v-radio v-bind:value="item.id"></v-radio></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </v-radio-group>
                                    </div>
                                </div>
                            </div>
                            <div v-if="makeupExactPossibilities.length === 0">
                                <p>It looks like there are no other makeup classes to choose.</p>
                            </div>

                            <v-container>
                                <v-layout>
                                    <v-btn text @click="viewMore()" v-if="staff">View More Classes</v-btn>
                                </v-layout>
                            </v-container>

                            <div v-if="staff && viewingMore && makeupOtherPossibilities.length > 0">
                                <div class="row">
                                    <div class="col-md-12">
                                        <v-radio-group class="col-md-12" v-model="classToSchedule" :mandatory="true">
                                            <table class="table table-striped table-bordered table-hover">
                                                <thead>
                                                <tr>
                                                    <th>Day</th>
                                                    <th>Date</th>
                                                    <th>Time</th>
                                                    <th>Class</th>
                                                    <th>Select</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="item in makeupOtherPossibilities">
                                                    <td>{{ item.date | moment("dddd") }}</td>
                                                    <td>{{ item.date | moment("l") }}</td>
                                                    <td>{{ item.date | moment("h:mm a") }}</td>
                                                    <td>{{ item.name }}</td>
                                                    <td><v-radio v-bind:value="item.id"></v-radio></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </v-radio-group>
                                    </div>
                                </div>
                            </div>
                            <div v-if="staff && viewingMore && makeupOtherPossibilities.length === 0">
                                <p>It looks like there are no makeup classes to choose.</p>
                            </div>


                            <v-btn text @click="e1 = 2">Back</v-btn>

                            <v-btn
                                    color="primary"
                                    @click="scheduleClass()"
                            >
                                Schedule
                            </v-btn>
                        </div>
                        <div v-if="thankYou">
                            <div class="row text-center">
                                <div class="col-md-12" v-if="!staff">
                                    <h2>Thank you!</h2>
                                    <p>Your child's makeup class is scheduled on {{ scheduledDate | moment("l") }} at {{ scheduledDate  | moment("h:mm a") }}.</p>
                                    <p>If you change your mind, please contact your studio manager at {{ phoneNumber}}.</p>
                                </div>
                                <div class="col-md-12" v-if="staff">
                                    <h2>Success!</h2>
                                    <p>You have scheduled this child for {{ scheduledDate | moment("l") }} at {{ scheduledDate  | moment("h:mm a") }}.</p>
                                </div>
                            </div>
                        </div>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-app>
    </div>
</template>

<script>
    import axios from 'axios';


    export default {
        props: ['studentFirstName', 'studentLastName', 'parentEmail'],
        data() {
            return {
                e1: 1,
                parent: {'email': this.parentEmail},
                student: {
                  'firstName': this.studentFirstName,
                  'lastName': this.studentLastName
                },
                radioGroup: 1,
                studentId: 0,
                missedClasses: [],
                scheduledClasses: [],
                makeupExactPossibilities: [],
                makeupOtherPossibilities: [],
                selectedClass: 0,
                classToSchedule: 0,
                projectId: 0,
                phoneNumber: '(877)353-0678',
                errors: {},
                staff: false,
                thankYou: false,
                success: false,
                loaded: true,
                viewingMore: false,
                scheduledDate: '',
                rules: {
                    required: value => !!value || 'Required.',
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'Invalid email.'
                    }
                }
            }
        },
        mounted() {
        },
        watch: {
            menu (val) {
                val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
            }
        },
        methods: {
            getStudentClasses() {
                if ( !this.parent.email ){
                    this.errors.email = "Email required!";
                }else if ( !this.student.firstName || !this.student.lastName ){
                    this.errors.name = "Student name required!";
                }else{
                    this.errors = {};

                    this.e1 = 2;
                    axios.post('/getStudentMakeupClasses', {
                        csrfToken: this.csrfToken,
                        student: this.student,
                        parent: this.parent
                    }).then(response => {
                        if ( response.data.success ){
                            this.missedClasses = response.data.missedClasses;
                            this.scheduledClasses = response.data.scheduledClasses;
                            this.studentId = response.data.studentId;
                            this.loaded = true;
                            this.staff = response.data.staff;
                        }else{
                            this.e1 = 1;
                            this.errors.notFound = response.data.msg;
                            this.missedClasses = [];
                        }
                    }).catch(error => {
                        this.loaded = true;
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors || {};
                        }
                    });
                }
            },
            getMakeupClasses() {
                this.e1 = 3;
                axios.post('/getMakeupPossibilities', {
                    csrfToken: this.csrfToken,
                    selectedClass: this.selectedClass,
                    studentId: this.studentId
                }).then(response => {
                    this.makeupExactPossibilities = response.data.makeupExactPossibilities;
                    this.makeupOtherPossibilities = response.data.makeupOtherPossibilities;
                    if ( response.data.projectId ){
                        this.projectId = response.data.projectId;
                    }
                    this.phoneNumber = response.data.phoneNumber;
                    this.loaded = true;
                }).catch(error => {
                    this.loaded = true;
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors || {};
                    }
                });
            },
            scheduleClass() {
                axios.post('/scheduleMakeupClass', {
                    csrfToken: this.csrfToken,
                    studentId: this.studentId,
                    projectId: this.projectId,
                    makeupClass: this.selectedClass,
                    classToSchedule: this.classToSchedule
                }).then(response => {
                    this.loaded = true;
                    this.success = true;
                    this.thankYou = true;
                    if ( response.data.scheduledDate ){
                        this.scheduledDate = response.data.scheduledDate;
                    }
                }).catch(error => {
                    this.loaded = true;
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors || {};
                    }
                });
            },
            viewMore(){
                this.viewingMore = true;
            }
        },
    }
</script>

<style>
    .theme--light.v-application {
        background: none;
    }
</style>