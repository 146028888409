<template>
    <form method="post" @submit.prevent="submit" action="/markStudentECPAttended">
        <alert-popup v-if="success" alert-type="success" alert-message="Attendance recorded!"></alert-popup>
        <input type="hidden" :value="csrfToken" name="_token"/>
        <v-icon v-model="attendanceStatus" @click="changeStatus">{{ icon }}</v-icon>
    </form>
</template>

<script>
    import axios from 'axios';

    export default {
        props: ['attendanceId', 'currentStatus'],
        data() {
            return {
                attendanceStatus: this.currentStatus,
                icon: null,
                errors: {},
                success: false,
                loaded: true,
                csrfToken: null,
            }
        },
        mounted() {
            this.csrfToken = document.querySelector('meta[name="csrf-token"]').content;
            if ( this.attendanceStatus == 0 ){ // No status
                this.icon = "radio_button_unchecked";
            }else if ( this.attendanceStatus == 1 ){ // Attended
                this.icon = "check_circle";
            }else if ( this.attendanceStatus == 2 ){ // Missed
                this.icon = "cancel";
            }else if ( this.attendanceStatus == 3 ){ // Made up
                this.icon = "done_all";
            }
        },
        methods: {
            changeStatus() {
                if ( this.attendanceStatus < 2 ){
                    this.attendanceStatus++;
                }else{
                    this.attendanceStatus = 0;
                }

                if ( this.attendanceStatus == 0 ){ // No status
                    this.icon = "radio_button_unchecked";
                }else if ( this.attendanceStatus == 1 ){ // Attended
                    this.icon = "check_circle";
                }else if ( this.attendanceStatus == 2 ){ // Missed
                    this.icon = "cancel";
                }else if ( this.attendanceStatus == 3 ){ // Made up
                    this.icon = "remove_circle";
                }

                this.toggleAttended();
            },
            toggleAttended() {
                if (this.loaded) {
                    this.loaded = false;
                    this.success = false;
                    this.errors = {};
                    axios.post('/markStudentECPAttended', {
                        csrfToken: this.csrfToken,
                        attendanceStatus: this.attendanceStatus,
                        attendanceId: this.attendanceId
                    }).then(response => {
                        this.fields = {}; //Clear input fields.
                        this.loaded = true;
                        this.success = true;
                    }).catch(error => {
                        this.loaded = true;
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors || {};
                        }
                    });
                }
            }
        },
    }
</script>