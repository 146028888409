<template>
    <div>
        <alert-popup v-if="success" alert-type="success" alert-message="Resources edited!"></alert-popup>

        <v-container ma-0 pa-0 grid-list-md text-xs-center fill-height>
            <v-layout row wrap>
                <v-flex xs12 sm12 md12>
                    <h3>Class #1</h3>
                </v-flex>
                <label>Make-up Video Link *</label>
                <v-flex xs12 sm6 md12>
                    <v-text-field
                            placeholder="Link to video in Dropbox"
                            solo
                            v-model="videoLink1"
                    ></v-text-field>
                </v-flex>
                <div v-if="errors && errors.videoLink1" class="text-danger">{{ errors.videoLink1 }}</div>

                <label>Video Password *</label>
                <v-flex xs12 sm6 md12>
                    <v-text-field
                            placeholder="Password to access video"
                            solo
                            v-model="videoPassword1"
                    ></v-text-field>
                </v-flex>
                <div v-if="errors && errors.videoPassword1" class="text-danger">{{ errors.videoPassword1 }}</div>
            </v-layout>
            <v-layout row wrap>
                <v-flex xs12 sm12 md12>
                    <h3>Class #2</h3>
                </v-flex>
                <label>Make-up Video Link *</label>
                <v-flex xs12 sm6 md12>
                    <v-text-field
                            placeholder="Link to video in Dropbox"
                            solo
                            v-model="videoLink2"
                    ></v-text-field>
                </v-flex>
                <div v-if="errors && errors.videoLink2" class="text-danger">{{ errors.videoLink2 }}</div>

                <label>Video Password *</label>
                <v-flex xs12 sm6 md12>
                    <v-text-field
                            placeholder="Password to access video"
                            solo
                            v-model="videoPassword2"
                    ></v-text-field>
                </v-flex>
                <div v-if="errors && errors.videoPassword2" class="text-danger">{{ errors.videoPassword2 }}</div>
            </v-layout>
            <v-layout row wrap>
                <v-flex xs12 sm12 md12>
                    <h3>Class #3</h3>
                </v-flex>
                <label>Make-up Video Link *</label>
                <v-flex xs12 sm6 md12>
                    <v-text-field
                            placeholder="Link to video in Dropbox"
                            solo
                            v-model="videoLink3"
                    ></v-text-field>
                </v-flex>
                <div v-if="errors && errors.videoLink3" class="text-danger">{{ errors.videoLink3 }}</div>

                <label>Video Password *</label>
                <v-flex xs12 sm6 md12>
                    <v-text-field
                            placeholder="Password to access video"
                            solo
                            v-model="videoPassword3"
                    ></v-text-field>
                </v-flex>
                <div v-if="errors && errors.videoPassword3" class="text-danger">{{ errors.videoPassword3 }}</div>
            </v-layout>
            <v-layout row wrap>
                <v-flex xs12 sm12 md12>
                    <h3>Class #4</h3>
                </v-flex>
                <label>Make-up Video Link *</label>
                <v-flex xs12 sm6 md12>
                    <v-text-field
                            placeholder="Link to video in Dropbox"
                            solo
                            v-model="videoLink4"
                    ></v-text-field>
                </v-flex>
                <div v-if="errors && errors.videoLink4" class="text-danger">{{ errors.videoLink4 }}</div>

                <label>Video Password *</label>
                <v-flex xs12 sm6 md12>
                    <v-text-field
                            placeholder="Password to access video"
                            solo
                            v-model="videoPassword4"
                    ></v-text-field>
                </v-flex>
                <div v-if="errors && errors.videoPassword4" class="text-danger">{{ errors.videoPassword4 }}</div>
            </v-layout>
            <v-layout row wrap>
                <v-flex xs12 sm12 md12>
                    <h3>Class #5</h3>
                </v-flex>
                <label>Make-up Video Link *</label>
                <v-flex xs12 sm6 md12>
                    <v-text-field
                            placeholder="Link to video in Dropbox"
                            solo
                            v-model="videoLink5"
                    ></v-text-field>
                </v-flex>
                <div v-if="errors && errors.videoLink5" class="text-danger">{{ errors.videoLink5 }}</div>

                <label>Video Password *</label>
                <v-flex xs12 sm6 md12>
                    <v-text-field
                            placeholder="Password to access video"
                            solo
                            v-model="videoPassword5"
                    ></v-text-field>
                </v-flex>
                <div v-if="errors && errors.videoPassword5" class="text-danger">{{ errors.videoPassword5 }}</div>
            </v-layout>

            <button class="btn btn-block btn-primary" @click="editResources()">Edit Resources</button>
        </v-container>
    </div>
</template>

<script>
    import axios from 'axios';


    export default {
        props: ['id'],
        data() {
            return {
                videoLink1: null,
                videoPassword1: null,
                videoLink2: null,
                videoPassword2: null,
                videoLink3: null,
                videoPassword3: null,
                videoLink4: null,
                videoPassword4: null,
                videoLink5: null,
                videoPassword5: null,
                errors: {},
                success: false,
                loaded: true,
                csrfToken: null,
            }
        },
        mounted() {
            this.getResources();
        },
        methods: {
            getResources() {
                axios.post('/getClassSeriesResources', {
                    id: this.id,
                }).then(response => {
                    this.videoLink1 = response.data.videoLink1;
                    this.videoPassword1 = response.data.videoPassword1;
                    this.videoLink2 = response.data.videoLink2;
                    this.videoPassword2 = response.data.videoPassword2;
                    this.videoLink3 = response.data.videoLink3;
                    this.videoPassword3 = response.data.videoPassword3;
                    this.videoLink4 = response.data.videoLink4;
                    this.videoPassword4 = response.data.videoPassword4;
                    this.videoLink5 = response.data.videoLink5;
                    this.videoPassword5 = response.data.videoPassword5;
                }).catch(error => {
                });
            },
            editResources() {
                if (this.loaded) {
                    this.loaded = false;
                    this.success = false;
                    this.errors = [];
                    if (!this.videoLink1) {
                        this.errors.videoLink1 = 'Video link required.';
                    }
                    if (!this.videoPassword1) {
                        this.errors.videoPassword1 = 'Video password required.';
                    }
                    if (!this.videoLink2) {
                        this.errors.videoLink2 = 'Video link required.';
                    }
                    if (!this.videoPassword2) {
                        this.errors.videoPassword2 = 'Video password required.';
                    }
                    if (!this.videoLink3) {
                        this.errors.videoLink3 = 'Video link required.';
                    }
                    if (!this.videoPassword3) {
                        this.errors.videoPassword3 = 'Video password required.';
                    }
                    if (!this.videoLink4) {
                        this.errors.videoLink4 = 'Video link required.';
                    }
                    if (!this.videoPassword4) {
                        this.errors.videoPassword4 = 'Video password required.';
                    }
                    if (!this.videoLink5) {
                        this.errors.videoLink5 = 'Video link required.';
                    }
                    if (!this.videoPassword5) {
                        this.errors.videoPassword5 = 'Video password required.';
                    }

                    axios.post('/editClassSeriesResources', {
                        csrfToken: this.csrfToken,
                        id: this.id,
                        videoLink1: this.videoLink1,
                        videoPassword1: this.videoPassword1,
                        videoLink2: this.videoLink2,
                        videoPassword2: this.videoPassword2,
                        videoLink3: this.videoLink3,
                        videoPassword3: this.videoPassword3,
                        videoLink4: this.videoLink4,
                        videoPassword4: this.videoPassword4,
                        videoLink5: this.videoLink5,
                        videoPassword5: this.videoPassword5,
                    }).then(response => {
                        this.fields = {}; //Clear input fields.
                        this.loaded = true;
                        this.success = true;
                    }).catch(error => {
                        this.loaded = true;
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors || {};
                        }
                    });
                }
            }
        },
    }
</script>