require('./bootstrap');
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'vuetify/dist/vuetify.min.css'
window.Vue = require('vue');
import Vuetify from 'vuetify'
import 'babel-polyfill'

Vue.filter('json', function (value) {
    return JSON.stringify(value);
});

Vue.use(Vuetify);

Vue.use(require('vue-moment'));

// Popup alert for feedback
Vue.component('alert-popup', require('./components/AlertPopup.vue').default);

// For Material Design icons outside of Vue
Vue.component('font-icon', require('./components/FontIcon.vue').default);

// Location page
Vue.component('location', require('./components/Location.vue').default);

// Select term session -- /locations
Vue.component('select-term-session', require('./components/SelectTermSessionForm.vue').default);

// Mark product as prepared -- /l/{location}/purchases
Vue.component('prepared-checkbox', require('./components/PrepareProduct.vue').default);

// User search with autocomplete - application header
Vue.component('student-search-autocomplete', require('./components/StudentSearchAutocomplete.vue').default);

// Mark student as attended - /class/{id}
Vue.component('mark-attended', require('./components/MarkAttended.vue').default);

// Mark student as attended ECP - /class/{id}
Vue.component('mark-ecp-attended', require('./components/MarkECPAttended.vue').default);

// Mark student as attended make-up class - /class/{id}
Vue.component('mark-makeup-attended', require('./components/MarkMakeupAttended.vue').default);

// Delete makeup - /class/{id}
Vue.component('delete-makeup', require('./components/DeleteMakeup.vue').default);

// Edit makeup time - /class/{id}
Vue.component('edit-makeup-time', require('./components/EditMakeupTime.vue').default);

// Add a student to a class - /class/{id}
Vue.component('student-write-in', require('./components/StudentWriteIn.vue').default);

// Delete a write-in - /class/{id}
Vue.component('delete-write-in', require('./components/DeleteWriteIn.vue').default);

// Add a project to a class - /class/{id}
Vue.component('project-write-in', require('./components/ProjectWriteIn.vue').default);

// Delete project write-in - /class/{id}
Vue.component('display-project-write-in', require('./components/DisplayProjectWriteIn.vue').default);

// Select another class - /class/{id}
Vue.component('class-selector', require('./components/ClassSelector.vue').default);

// Delete class - /class/{id}
Vue.component('delete-class', require('./components/DeleteClass.vue').default);

// Textarea form - student profile
Vue.component('textarea-form', require('./components/TextareaForm.vue').default);

// Give points form - student profile
Vue.component('points-form', require('./components/PointsForm.vue').default);

// Subtract points form - student profile
Vue.component('subtract-points-form', require('./components/SubtractPointsForm.vue').default);

// Teacher note - student profile
Vue.component('teacher-note', require('./components/TeacherNote.vue').default);

// Mark product as handed out -/class/{id}
Vue.component('handout-product-form', require('./components/HandOutProduct.vue').default);

// Create Project
Vue.component('create-project', require('./components/CreateProject.vue').default);

// Edit Project
Vue.component('edit-project', require('./components/EditProject.vue').default);

// View Project Schedule
Vue.component('view-project-schedule', require('./components/ViewProjectSchedule.vue').default);

// Schedule Project
Vue.component('schedule-project', require('./components/ScheduleProject.vue').default);

// Parent Reschedule form
Vue.component('parent-reschedule', require('./components/ParentReschedule.vue').default);

// Edit Rescheduled Class - /l/{location}/rescheduled/{id}
Vue.component('edit-rescheduled-class', require('./components/EditRescheduledClass.vue').default);

// Today's classes
Vue.component('todays-classes', require('./components/TodaysClasses.vue').default);

// Process - /l/{location}/age-report
Vue.component('process-inappropriate-age-student', require('./components/ProcessInappropriateAgeStudent.vue').default);

// Edit user
Vue.component('edit-user', require('./components/EditUser.vue').default);

// Export Form
Vue.component('export-form', require('./components/ExportForm.vue').default);

// Class Series Resources
Vue.component('class-series-resources', require('./components/ClassSeriesResources.vue').default);

// Makeup switch
Vue.component('switch-makeups-on-off', require('./components/SwitchMakeupsOnOff.vue').default);
Vue.component('erm-lass-ategories', require('./components/TermClassCategories.vue').default);
Vue.component('term-class-categories', require('./components/TermClassCategories.vue').default);

Vue.component('class-category-selector', require('./components/ClassCategorySelector.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// const app = new Vue({
//     el: '#app'
// });


const app = new Vue({
    el: '#app',
    vuetify: new Vuetify({
        icons: {
            iconfont: 'md', // default - only for display purposes
        },
    }),
});
