<template>
    <div>
        <v-progress-circular
            v-show="!loaded"
            indeterminate
            color="primary"
        ></v-progress-circular>
        <v-container
            v-show="loaded"
            ma-0
            pa-0
            grid-list-md
            text-xs-center
            fill-height
        >
            <v-layout row wrap>
                <v-flex xl12 text-xs-left>
                    <h4>
                        <a href="/locations">Home</a> >
                        <a v-bind:href="'/l/' + location.slug">{{
                            location.name
                        }}</a>
                        > Today
                    </h4>
                </v-flex>
            </v-layout>
        </v-container>
        <v-container
            v-show="loaded"
            ma-0
            pa-0
            grid-list-md
            text-xs-center
            fill-height
        >
            <v-layout row wrap>
                <v-flex xl6 text-xs-left>
                    <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="date"
                        lazy
                        transition="scale-transition"
                        offset-y
                        full-width
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="date"
                                label="Picker in menu"
                                prepend-icon="event"
                                readonly
                                v-on="on"
                                solo
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false"
                                >Cancel</v-btn
                            >
                            <v-btn text color="primary" @click="save(date)"
                                >OK</v-btn
                            >
                        </v-date-picker>
                    </v-menu>
                </v-flex>
                <v-flex xs3 text-xs-left>
                    <v-select
                        v-model="timeSelected"
                        :items="times"
                        label="Filter by Time"
                        @change="getTodaysClasses()"
                        solo
                    ></v-select>
                </v-flex>
                <v-flex xs3 text-xs-left>
                    <v-select
                        v-model="categorySelected"
                        :items="classCategories"
                        label="Filter by Category"
                        item-value="id"
                        item-text="name"
                        @change="getTodaysClasses()"
                        solo
                    ></v-select>
                </v-flex>
            </v-layout>
        </v-container>
        <v-container v-if="loaded && todaysClasses.length > 0" ma-0 pa-0 fluid>
            <v-layout v-for="single in todaysClasses" v-bind:key="single.id">
                <div class="col-md-12 mb-4">
                    <div class="card">
                        <h5 class="card-header">
                            <span class="row">
                                <span class="col-md-8"
                                    >{{ single.name }} -
                                    {{
                                        single.startTime | moment("h:mm a")
                                    }}</span
                                >
                                <span class="col-md-4 text-right"
                                    ><a
                                        v-bind:href="'/class/' + single.classId"
                                        class="btn btn-primary"
                                        >Take Attendance</a
                                    ></span
                                >
                            </span>
                        </h5>
                        <div class="card-body">
                            <ul class="list-group list-group-horizontal mb-2">
                                <li class="list-group-item col-md-4">
                                    <div v-if="single.projects.length > 0">
                                        <div v-for="project in single.projects">
                                            <p>
                                                {{ project.name }} -
                                                {{ project.code }}
                                            </p>
                                        </div>
                                    </div>
                                    <div v-if="single.projects.length === 0">
                                        No project(s) assigned.
                                    </div>
                                </li>
                                <li class="list-group-item col-md-4">
                                    Enrolled Students - {{ single.enrolled }}
                                </li>
                                <li class="list-group-item col-md-4">
                                    Make-up Students -
                                    {{ single.makeupStudents.length }}
                                </li>
                            </ul>

                            <div v-if="single.makeupStudents.length > 0">
                                <div class="table-responsive">
                                    <h5 class="card-title">Make-up Students</h5>
                                    <table
                                        class="table table-striped table-bordered table-hover"
                                    >
                                        <thead>
                                            <tr>
                                                <th>Student</th>
                                                <th>Project</th>
                                                <th>Start Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="makeup in single.makeupStudents"
                                            >
                                                <td>
                                                    <a
                                                        v-bind:href="
                                                            '/profile/' +
                                                            makeup.studentId
                                                        "
                                                        >{{
                                                            makeup.studentName
                                                        }}</a
                                                    >
                                                </td>
                                                <td>
                                                    <a
                                                        v-bind:href="
                                                            '/project/' +
                                                            makeup.projectId
                                                        "
                                                        >{{
                                                            makeup.projectName
                                                        }}</a
                                                    >
                                                </td>
                                                <td>
                                                    {{
                                                        makeup.startTime
                                                            | moment("h:mm a")
                                                    }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-layout>
        </v-container>
        <v-container v-if="loaded && todaysClasses.length == 0" ma-0 pa-0 fluid>
            <v-layout>
                <div v-if="todaysClasses.length === 0">
                    <p>There are no classes scheduled for this day!</p>
                </div>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import axios from "axios";

export default {
    props: ["locationName", "locationId"],
    mounted() {
        axios
            .post("/getLocationInfo", {
                locationId: this.locationId,
            })
            .then((response) => {
                this.location = response.data;
            });

        axios.post("/getAllCategories", {}).then((response) => {
            this.classCategories = response.data;
        });

        this.times = [
            "8:00 AM",
            "9:00 AM",
            "10:00 AM",
            "11:00 AM",
            "12:00 PM",
            "1:00 PM",
            "2:00 PM",
            "3:00 PM",
            "4:00 PM",
            "5:00 PM",
            "6:00 PM",
            "7:00 PM",
        ];

        this.getTodaysClasses();

        this.csrfToken = document.querySelector(
            'meta[name="csrf-token"]'
        ).content;
    },
    data() {
        return {
            todaysClasses: [],
            times: [],
            classCategories: [],
            location: null,
            dateSelected: "",
            timeSelected: "",
            categorySelected: "",
            date: new Date().toISOString().substr(0, 10),
            menu: false,
            modal: false,
            menu2: false,
            errors: {},
            success: false,
            loaded: false,
            csrfToken: null,
        };
    },
    methods: {
        save(date) {
            this.loaded = false;
            this.$refs.menu.save(date);
            this.dateSelected = date;
            this.getTodaysClasses();
        },
        getTodaysClasses() {
            axios
                .post("/getTodayClasses", {
                    locationId: this.locationId,
                    dateSelected: this.dateSelected,
                    timeSelected: this.timeSelected,
                    categorySelected: this.categorySelected,
                })
                .then((response) => {
                    this.todaysClasses = response.data;
                    this.loaded = true;
                });
        },
    },
};
</script>

<style>
.v-select > .v-input__control > .v-input__slot > .v-menu > div {
    top: 33px !important;
    left: 0 !important;
}
.v-btn--active {
    background-color: #3490dc;
}
</style>
