<template>
    <div>
        <alert-popup v-if="successProcessed" alert-type="success" alert-message="Student marked as processed!"></alert-popup>
        <alert-popup v-if="successStatusRemoved" alert-type="success" alert-message="Student processed status removed"></alert-popup>
        <v-checkbox
                v-model="markAsProcessed"
                @click.native="toggleProcessed"
        ></v-checkbox>
    </div>
</template>

<script>
    import axios from 'axios';


    export default {
        props: ['studentId', 'termClassId', 'processedStatus'],
        data() {
            return {
                markAsProcessed: false,
                errors: {},
                successProcessed: false,
                successStatusRemoved: false,
                loaded: true,
                csrfToken: null,
            }
        },
        mounted() {
            if ( this.processedStatus > 0 ){
                this.markAsProcessed = true;
            }else{
                this.markAsProcessed = false;
            }
            this.csrfToken = document.querySelector('meta[name="csrf-token"]').content;
        },
        methods: {
            toggleProcessed() {
                if (this.loaded) {
                    this.loaded = false;
                    this.success = false;
                    this.errors = {};
                    axios.post('/markAgeStudentProcessed', {
                        csrfToken: this.csrfToken,
                        markAsProcessed: this.markAsProcessed,
                        studentId: this.studentId,
                        termClassId: this.termClassId
                    }).then(response => {
                        this.fields = {}; //Clear input fields.
                        this.loaded = true;
                        if ( this.markAsProcessed ){
                            this.successProcessed = true;
                        }else{
                            this.successStatusRemoved = true;
                        }
                    }).catch(error => {
                        this.loaded = true;
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors || {};
                        }
                    });
                }
            }
        },
    }
</script>