<template>
    <div>
        <alert-popup
            v-if="showMsg"
            :alert-type="alertType"
            :alert-message="message"
        ></alert-popup>
        <v-select
            :items="categories"
            label="Change Category"
            item-text="name"
            item-value="id"
            v-model="category"
            @change="updateCategory()"
        ></v-select>
    </div>
</template>

<script>
import axios from "axios";

export default {
    props: ["categoryId", "classId"],
    mounted() {
        this.fetchCategories();
        this.csrfToken = document.querySelector(
            'meta[name="csrf-token"]'
        ).content;
    },
    data() {
        return {
            category: null,
            categories: [],
            errors: {},
            showMsg: false,
            alertType: "success",
            message: "",
            success: false,
            loaded: false,
            csrfToken: null,
        };
    },
    watch: {
        // Watch for changes in categoryId and update the selected category
        categoryId(newVal) {
            this.setCategoryById(newVal);
        },
    },
    methods: {
        // Fetch all categories from the server
        fetchCategories() {
            axios.post("/api/term-categories").then((response) => {
                this.categories = response.data.categories;

                console.log("categories", this.categories);
                // Set the category based on the passed categoryId
                this.setCategoryById(this.categoryId);
            });
        },

        // Helper method to find and set the category by ID
        setCategoryById(categoryId) {
            if (this.categories.length > 0) {
                const matchingCategory = this.categories.find(
                    (cat) => cat.id === Number(categoryId)
                );
                console.log("categoryId", categoryId);
                console.log(
                    "matchingCategory",
                    matchingCategory ?? "not yet defined"
                );
                if (matchingCategory) {
                    this.category = matchingCategory; // Set the full category object
                }
            }
        },

        updateCategory() {
            axios
                .post("/api/term-class/update-category", {
                    termClassId: this.classId,
                    termClassCategoryId: this.category,
                })
                .then((response) => {
                    console.log("Category updated successfully", response.data);
                    if (response.data.success === true) {
                        this.showMsg = true;
                        this.alertType = "success";
                        this.message = response.data.message;
                        // Reset showMsg after a short delay
                        setTimeout(() => {
                            this.showMsg = false;
                        }, 3000);
                    }
                })
                .catch((error) => {
                    console.error("Error updating category", error);
                });
        },
    },
};
</script>
