<template>
    <div class="category-edit">
        <!-- Form for adding/editing categories -->
        <form @submit.prevent="saveCategory">
            <strong>Add or Edit Category:</strong>
            <v-text-field
                v-model="form.name"
                type="text"
                label="New Category"
                style="max-width: 65%"
                outlined
            ></v-text-field>
            <v-btn type="submit" color="primary">
                {{ editMode ? "Update" : "Add" }} Category
            </v-btn>
        </form>

        <!-- Categories Table -->
        <table>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="category in categories" :key="category.id">
                    <td>{{ category.id }}</td>
                    <td>{{ category.name }}</td>
                    <td>
                        <v-btn
                            text
                            color="danger"
                            @click="editCategory(category)"
                            >Edit</v-btn
                        >
                        <v-btn
                            text
                            color="primary"
                            @click="deleteCategory(category.id)"
                            >Delete</v-btn
                        >
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            categories: [],
            form: {
                id: null,
                name: "",
            },
            editMode: false,
        };
    },
    methods: {
        // Fetch all categories
        fetchCategories() {
            axios.post("/api/term-categories").then((response) => {
                this.categories = response.data.categories;
                console.log(response.data);
            });
        },

        // Save a new category or update an existing one
        saveCategory() {
            if (this.editMode) {
                axios
                    .put(
                        `/api/term-class-categories/${this.form.id}`,
                        this.form
                    )
                    .then(this.fetchCategories)
                    .finally(() => this.resetForm());
            } else {
                axios
                    .post("/api/term-class-categories", this.form)
                    .then(this.fetchCategories)
                    .finally(() => this.resetForm());
            }
        },

        // Edit a category
        editCategory(category) {
            this.form = { ...category };
            this.editMode = true;
        },

        // Delete a category
        deleteCategory(id) {
            axios
                .delete(`/api/term-class-categories/${id}`)
                .then(this.fetchCategories);
        },

        // Reset form fields
        resetForm() {
            this.form = { id: null, name: "" };
            this.editMode = false;
        },
    },
    mounted() {
        this.fetchCategories();
    },
};
</script>
<style>
th,
td {
    padding: 0.41rem 1rem;
}
th:nth-child(2),
th:nth-child(3) {
    text-align: center;
}
</style>
