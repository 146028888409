import { render, staticRenderFns } from "./HandOutProduct.vue?vue&type=template&id=be66a0c8"
import script from "./HandOutProduct.vue?vue&type=script&lang=js"
export * from "./HandOutProduct.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports