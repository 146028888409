<template>
    <div class="text-xs-center">
        <v-dialog
                v-model="dialog"
                width="500"
        >
            <template v-slot:activator="{ on }">
                <button
                        class="btn btn-danger"
                        v-on="on"
                >
                    - Points
                </button>
            </template>

            <v-card>
                <form method="post" @submit.prevent="submit" action="/addPoints">
                    <v-card-title
                            class="headline grey lighten-2"
                            primary-title
                    >
                        Subtract Points
                    </v-card-title>

                    <v-card-text>
                        <alert-popup v-if="success" alert-type="success" alert-message="Points added to student!"></alert-popup>
                        <input type="hidden" :value="csrfToken" name="_token"/>
                        <v-text-field
                                v-model="totalPoints"
                                label="Points"
                                mask="###"
                        ></v-text-field>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                type="submit"
                                color="primary"
                                text
                                @click="dialog = false"
                        >
                            Remove from Student
                        </v-btn>
                    </v-card-actions>
                </form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import axios from 'axios';


    export default {
        props: ['identifyingId'],
        data() {
            return {
                totalPoints: null,
                errors: {},
                success: false,
                loaded: true,
                csrfToken: null,
                dialog: null
            }
        },
        mounted() {
            this.csrfToken = document.querySelector('meta[name="csrf-token"]').content;
        },
        methods: {
            submit() {
                if (this.loaded) {
                    this.loaded = false;
                    this.success = false;
                    this.errors = {};
                    axios.post('/subtractPoints', {
                        csrfToken: this.csrfToken,
                        totalPoints: this.totalPoints,
                        identifyingId: this.identifyingId
                    }).then(response => {
                        this.fields = {}; //Clear input fields.
                        this.loaded = true;
                        this.success = true;
                        window.location.reload();
                    }).catch(error => {
                        this.loaded = true;
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors || {};
                        }
                    });
                }
            }
        },
    }
</script>