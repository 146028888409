<template>
    <div class="text-center">
        <alert-popup v-if="success" alert-type="success" alert-message="Class deleted!"></alert-popup>
        <alert-popup v-if="errorMessage" alert-type="error" :alert-message="errorMessage"></alert-popup>
        <button class="btn btn-danger" @click="deleteClass()">Delete Class</button>
    </div>
</template>

<script>
    import axios from 'axios';


    export default {
        props: ['classId'],
        data() {
            return {
                errorMessage: "",
                success: false,
            }
        },
        mounted() {
        },
        methods: {
            deleteClass() {
                if ( confirm("Are you sure you want to delete this class?")) {
                    axios.post('/deleteClass', {
                        csrfToken: this.csrfToken,
                        classId: this.classId,
                    }).then(response => {
                        console.log(response.data);
                        if ( response.data.success ){
                            this.success = true;
                            console.log('success!');
                            window.location.href = '/locations', true;
                        }else{
                            this.errorMessage = response.data.message;
                        }
                    }).catch(error => {
                        if (error.response.status === 422) {
                            this.errorMessage = error.response.data.errors || "";
                        }
                    });
                }
            }
        },
    }
</script>