<template>
        <v-snackbar
                v-model="snackbar"
                :timeout="timeout"
                color="success"
                :bottom="true"
                :right="true"
        >
            {{ alertMessage }}
            <v-btn
                    dark
                    text
                    @click="snackbar = false"
            >
                Close
            </v-btn>
        </v-snackbar>
</template>

<script>
    export default {
        props: ['alertType','alertMessage'],
        data () {
            return {
                snackbar: true,
                timeout: 2000,
            }
        }
    }
</script>