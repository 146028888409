<template>
    <div>
        <alert-popup v-if="success" alert-type="success" alert-message="Makeup class rescheduled!"></alert-popup>
        <v-progress-circular
                v-show="!loaded"
                indeterminate
                color="primary"
        ></v-progress-circular>
        <div v-show="loaded">
            <v-container ma-0 pa-0 grid-list-md text-xs-center fill-height>
                <v-layout row wrap>
                    <v-flex xs4 text-left>
                        <h4>Student</h4>
                        <p><a v-bind:href="'/profile/'+ student.id">{{ student.name }}</a></p>
                        <div>
                            <p><strong>Make-up Project:</strong></p>
                            <div v-if="projects.length > 0">
                                <p v-for="item in projects">{{ item.name }} - {{ item.code}}</p>
                            </div>

                            <div v-if="projects.length === 0">
                                No projects assigned.
                            </div>
                        </div>
                    </v-flex>
                    <v-flex xs4 text-left>
                        <h4>Missed Class</h4>
                        <p><a v-bind:href="'/class/'+ missedClass.classId">{{ missedClass.name }}</a></p>
                        <p>{{ missedClass.locationName }} - {{ missedClass.startTime }}</p>
                    </v-flex>
                    <v-flex xs4 text-left>
                        <h4>Scheduled Makeup Class</h4>
                        <p><a v-bind:href="'/class/'+ makeupClass.classId">{{ makeupClass.name }}</a></p>
                        <p>{{ makeupClass.locationName }} - {{ makeupClass.startTime }}</p>
                        <p><strong>Scheduled Start Time:</strong> {{ startTime }}</p>
                    </v-flex>
                </v-layout>
            </v-container>

            <v-container ma-0 pa-0 grid-list-md text-xs-center fill-height>
                <v-layout row wrap>
                    <v-flex xs12 text-xs-left>
                        <h3>Search for a New Makeup Class</h3>
                    </v-flex>
                </v-layout>
            </v-container>
            <v-container ma-0 pa-0 grid-list-md text-xs-center fill-height>
                <v-layout row wrap>
                    <v-flex xs12 text-xs-left>
                        <v-select
                                v-model="selectedCategory"
                                :items="classCategories"
                                label="Select a Class Category"
                                item-value="id"
                                item-text="name"
                                solo
                        ></v-select>
                    </v-flex>
                </v-layout>
            </v-container>

            <v-container ma-0 pa-0 grid-list-md text-xs-center fill-height>
                <v-layout row wrap justify-center>
                    <v-flex xs6 text-xs-left>
                        <v-select
                                v-model="selectedWeekDay"
                                :items="daysOfWeek"
                                label="Select a Day"
                                solo
                                @change="populateClasses()"
                        ></v-select>
                    </v-flex>
                    <v-flex xs6 text-xs-left>
                        <v-select
                                v-model="selectedTime"
                                :items="dayTimes"
                                label="Select a Time"
                                solo
                                @change="populateClasses()"
                        ></v-select>
                    </v-flex>
                </v-layout>
            </v-container>

            <v-container v-if="selectedCategory || selectedWeekDay || selectedTime" ma-0 pa-0 grid-list-md text-xs-center fill-height>
                <v-layout row wrap>
                    <v-flex xs12 text-xs-left>
                        <h3>Select a New Makeup Class</h3>
                    </v-flex>
                </v-layout>
            </v-container>

            <v-container v-if="selectedCategory || selectedWeekDay || selectedTime" ma-0 pa-0 grid-list-md text-xs-center fill-height>
                <v-layout row wrap justify-center>
                    <v-flex xs12 text-xs-left>
                        <v-select
                                v-model="selectedPossibleClass"
                                :items="possibilities"
                                label="Select a Makeup Class"
                                item-value="periodId"
                                item-text="label"
                                solo
                        ></v-select>
                    </v-flex>
                </v-layout>
            </v-container>

            <v-container v-if="selectedPossibleClass" ma-0 pa-0 grid-list-md text-xs-center fill-height>
                <v-layout row wrap justify-center>
                    <v-flex xs6 text-xs-left>
                        <v-select
                                v-model="newProject"
                                :items="projectList"
                                label="Change Project"
                                item-value="id"
                                item-text="label"
                                solo
                        ></v-select>
                    </v-flex>
                    <v-flex xs6 text-xs-left>
                        <v-select
                                v-model="adjustTime"
                                :items="adjustableTimes"
                                label="Adjust Time"
                                solo
                        ></v-select>
                    </v-flex>
                </v-layout>
            </v-container>

            <v-container v-if="selectedPossibleClass" ma-0 pa-0 grid-list-md text-xs-center fill-height>
                <v-layout row wrap justify-center>
                    <v-flex xs12 text-xs-left>
                        <v-btn-block class="btn btn-lg btn-block btn-primary" @click="saveRescheduledClass()">Save Rescheduled Class</v-btn-block>
                    </v-flex>
                </v-layout>
            </v-container>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        props: ['missedAttendanceId','locationId'],
        mounted() {
            this.instantiateData();
            this.getClassCategories();
            this.daysOfWeek = ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'];
            this.dayTimes = ['9:00 AM','10:00 AM','11:00 AM','12:00 PM','1:00 PM','2:00 PM','3:00 PM','4:00 PM'];
            this.adjustableTimes = ['-15 minutes', '+15 minutes'];

            this.csrfToken = document.querySelector('meta[name="csrf-token"]').content
        },
        data() {
            return {
                student: null,
                missedClass: null,
                makeupClass: null,
                projects: [],
                classCategories: [],
                selectedCategory: 0,
                selectedWeekDay: '',
                selectedTime: '',
                possibilities: [],
                selectedPossibleClass: 0,
                newProject: 0,
                projectList: [],
                adjustableTimes: [],
                adjustTime: '',
                startTime: null,
                errors: {},
                success: false,
                loaded: false,
                csrfToken: null,
            }
        },
        methods: {
            instantiateData(){
                axios.post('/getMissedClassData', {
                    missedAttendanceId: this.missedAttendanceId
                }).then(response => {
                    this.student = response.data.student;
                    this.missedClass = response.data.missedClass;
                    this.makeupClass = response.data.makeupClass;
                    this.projects = response.data.projects;
                    this.startTime = response.data.startTime;
                    this.loaded = true;
                });
            },
            getClassCategories(){
                axios.post('/getAllCategories', {
                }).then(response => {
                    this.classCategories = response.data;
                    this.loaded = true;
                });
            },
            populateClasses(){
                axios.post('/getPotentialMakeupClasses', {
                    weekDay: this.selectedWeekDay,
                    hour: this.selectedTime,
                    locationId: this.locationId,
                    categoryId: this.selectedCategory
                }).then(response => {
                    this.possibilities = response.data;
                    this.getProjects();
                });
            },
            getProjects(){
                axios.post('/getMakeupProjects', {
                    locationId: this.locationId,
                }).then(response => {
                    this.projectList = response.data;
                });
            },
            saveRescheduledClass(){
                axios.post('/saveRescheduledClass', {
                    missedAttendanceId: this.missedAttendanceId,
                    newProject: this.newProject,
                    adjustTime: this.adjustTime,
                    selectedPossibleClass: this.selectedPossibleClass
                }).then(response => {
                    this.success = true;
                    this.makeupClass = response.data.makeupClass;
                    this.projects = response.data.projects;
                    this.startTime = response.data.startTime;
                });
            }
        }
    }
</script>

<style>
    .v-select > .v-input__control > .v-input__slot > .v-menu > div{
        top: 33px !important;
        left: 0 !important;
    }
</style>