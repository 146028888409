<template>
    <div>
        <alert-popup v-if="success" alert-type="success" alert-message="Term session saved!"></alert-popup>
        <v-container ma-0 pa-0 grid-list-md text-xs-center fill-height>
            <v-layout row wrap justify-center>
                <v-flex xs3 text-xs-left>
                    <p><strong>Selected Session:</strong></p>
                    <p><span v-if="currentSession">{{ currentSession }}</span>
                        <span v-if="!currentSession">None.</span></p>
                </v-flex>
                <v-flex xs9>
                    <v-select
                            v-model="term_session_id"
                            :items="term_sessions"
                            item-text="name"
                            item-value="id"
                            required
                            id="term-select"
                            placeholder="Select a Session"
                            solo
                            @change="saveSession()"
                    ></v-select>
                </v-flex>
            </v-layout>
        </v-container>
        <div v-if="errors && errors.message" class="text-danger">{{ errors.term_session_id[0] }}</div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        props: ['currentSession'],
        mounted() {
            axios.post('api/get_term_sessions', {})
                .then(response => {
                    this.term_sessions = response.data;
                });

            this.csrfToken = document.querySelector('meta[name="csrf-token"]').content
        },
        data() {
            return {
                term_sessions: null,
                term_session_id: null,
                errors: {},
                success: false,
                loaded: true,
                csrfToken: null,
            }
        },
        methods: {
            saveSession() {
                if (this.loaded) {
                    this.loaded = false;
                    this.success = false;
                    this.errors = {};
                    axios.post('/saveTermSession', {
                        csrfToken: this.csrfToken,
                        term_session_id: this.term_session_id
                    }).then(response => {
                        this.fields = {}; //Clear input fields.
                        this.loaded = true;
                        this.success = true;
                        window.location.reload();
                    }).catch(error => {
                        this.loaded = true;
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors || {};
                        }
                    });
                }

            },
        }
    }
</script>

<style>
    .v-select > .v-input__control > .v-input__slot > .v-menu > div{
        top: 33px !important;
        left: 0 !important;
    }
</style>