<template>
    <div>
        <div v-if="!searching">
            <v-icon @click="searching = true" class="mt-1">search</v-icon>
        </div>
        <div v-if="searching">

            <div class="input-group">
                <input type="text" placeholder="Search Students..." v-model="query" v-on:keyup="autoComplete" class="form-control">
                <div class="input-group-append">
                    <v-icon @click="searching = false">clear</v-icon>
                </div>
            </div>


            <div class="panel-footer" v-if="results.length">
                <ul class="list-group">
                    <li class="list-group-item" v-for="result in results">
                        <a v-bind:href="'/profile/'+ result.id">{{ result.first_name }} {{ result.last_name }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    export default{
        data(){
            return {
                searching: false,
                query: '',
                results: []
            }
        },
        methods: {
            autoComplete(){
                this.results = [];
                if(this.query.length > 2){
                    axios.post('/api/student_search',{
                        query: this.query
                    }).then(response => {
                        this.results = response.data;
                    });
                }
            }
        }
    }
</script>
<style>
    .panel-footer {
        position: absolute;
        z-index: 1;
        min-width: 177px;
    }
</style>