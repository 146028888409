<template>
    <div>
        <alert-popup v-if="success" alert-type="success" alert-message="Export started..."></alert-popup>


    </div>
</template>

<script>
    import axios from 'axios';


    export default {
        props: ['locationId'],
        data() {
            return {
                errors: {},
                success: false,
                loaded: true,
            }
        },
        methods: {
            startExport() {
                if (this.loaded) {
                    this.loaded = false;
                    this.success = false;
                    this.errors = [];
                    if (!this.name) {
                        this.errors.name = 'Name required.';
                    }
                    if (!this.code) {
                        this.errors.code = 'Project code required.';
                    }

                    axios.post('/createProject', {
                        csrfToken: this.csrfToken,
                        locationId: this.locationId,
                        name: this.name,
                        code: this.code,
                        description: this.description
                    }).then(response => {
                        this.fields = {}; //Clear input fields.
                        this.loaded = true;
                        this.success = true;
                    }).catch(error => {
                        this.loaded = true;
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors || {};
                        }
                    });
                }
            }
        },
    }
</script>