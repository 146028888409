<template>
    <form method="post" @submit.prevent="submit" action="/markProductPrepared">
        <alert-popup v-if="successPrepared" alert-type="success" alert-message="Product marked as prepared!"></alert-popup>
        <alert-popup v-if="successStatusRemoved" alert-type="success" alert-message="Product prepared status removed"></alert-popup>
        <input type="hidden" :value="csrfToken" name="_token"/>
        <v-checkbox
                v-model="markAsPrepared"
                @click.native="togglePrepared"
        ></v-checkbox>
    </form>
</template>

<script>
    import axios from 'axios';


    export default {
        props: ['purchaseId', 'preparedStatus'],
        data() {
            return {
                markAsPrepared: false,
                errors: {},
                successPrepared: false,
                successStatusRemoved: false,
                loaded: true,
                csrfToken: null,
            }
        },
        mounted() {
            if ( this.preparedStatus === "true" ){
                this.markAsPrepared = true;
            }else{
                this.markAsPrepared = false;
            }
            this.csrfToken = document.querySelector('meta[name="csrf-token"]').content;
        },
        methods: {
            togglePrepared() {
                if (this.loaded) {
                    this.loaded = false;
                    this.success = false;
                    this.errors = {};
                    axios.post('/markProductPrepared', {
                        csrfToken: this.csrfToken,
                        markAsPrepared: this.markAsPrepared,
                        purchaseId: this.purchaseId
                    }).then(response => {
                        this.fields = {}; //Clear input fields.
                        this.loaded = true;
                        if ( this.markAsPrepared ){
                            this.successPrepared = true;
                        }else{
                            this.successStatusRemoved = true;
                        }
                    }).catch(error => {
                        this.loaded = true;
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors || {};
                        }
                    });
                }
            }
        },
    }
</script>