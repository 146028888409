<template>
    <v-app class="no-height">
        <alert-popup
            v-if="showMsg"
            :alert-type="alertType"
            :alert-message="msg"
        ></alert-popup>

        <v-switch
            v-model="makeupsAllowed"
            :label="`Makeups ${makeupsAllowed ? '' : 'Not'} Allowed`"
            @change="updateMakeupsAllowed()"
        ></v-switch>
    </v-app>
</template>

<script>
export default {
    props: ["value", "classId"],
    data() {
        return {
            makeupsAllowed: true,
            showMsg: false,
            alertType: "success",
            msg: "",
        };
    },
    mounted() {
        this.makeupsAllowed = this.value;
    },
    methods: {
        updateMakeupsAllowed() {
            this.showMsg = false;

            axios
                .post("/updateMakeupsAllowed", {
                    makeupsAllowed: this.makeupsAllowed,
                    classId: this.classId,
                })
                .then((response) => {
                    this.showMsg = true;
                    this.alertType = response.data.success;
                    this.msg = response.data.message;
                });
        },
    },
};
</script>

<style>
.no-height .v-application--wrap {
    min-height: 0;
}
</style>
