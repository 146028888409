<template>
    <div class="text-center d-inline">
        <alert-popup v-if="success" alert-type="success" alert-message="Makeup deleted!"></alert-popup>
        <alert-popup v-if="errorMessage" alert-type="error" :alert-message="errorMessage"></alert-popup>
        <span v-if="!editing">{{ makeupTime }}</span>
        <input type="text" v-model="newMakeupTime" v-if="editing" size="8" v-on:keyup="changedValue()">
        <button class="btn btn-outline-primary btn-sm" @click="editMakeup()" v-if="changed">
            <v-icon small>done</v-icon>
        </button>

        <button class="btn btn-outline-primary btn-sm" @click="toggleEditing()">
            <v-icon small v-if="editing">cancel</v-icon>
            <v-icon small v-if="!editing">edit</v-icon>
        </button>
    </div>
</template>

<script>
    import axios from 'axios';


    export default {
        props: ['attendanceId', 'makeupTime'],
        data() {
            return {
                newMakeupTime: "",
                editing: false,
                changed: false,
                errorMessage: "",
                success: false,
            }
        },
        mounted() {
            this.newMakeupTime = this.makeupTime;
        },
        methods: {
            toggleEditing() {
                if (this.editing){
                    this.editing = false;
                    this.newMakeupTime = this.makeupTime;
                    this.changed = false;
                }else{
                    this.editing = true;
                }
            },
            changedValue(){
                this.changed = true;
            },
            editMakeup() {
                if ( confirm("Are you sure you want to edit this makeup time?")) {
                    axios.post('/editMakeupTime', {
                        csrfToken: this.csrfToken,
                        attendanceId: this.attendanceId,
                        newMakeupTime: this.newMakeupTime
                    }).then(response => {
                        console.log(response.data);
                        if ( response.data.success ){
                            this.success = true;
                            window.location.reload();
                        }else{
                            this.errorMessage = response.data.message;
                        }
                    }).catch(error => {
                        if (error.response.status === 422) {
                            this.errorMessage = error.response.data.errors || "";
                        }
                    });
                }
            }
        },
    }
</script>