<template>
    <div>
        <alert-popup v-if="showStatus" alert-type="success" alert-message="Project write-in deleted!"></alert-popup>
        {{ projectCode }} <v-icon v-if="outreach === '1'" @click="deleteProjectWriteIn()" size="18">delete</v-icon>
    </div>
</template>

<script>
    import axios from 'axios';


    export default {
        props: ['projectWriteInId', 'projectCode', 'outreach'],
        data() {
            return {
                errors: {},
                showStatus: false,
                status: '',
                statusMessage: '',
                loaded: true,
            }
        },
        methods: {
            deleteProjectWriteIn() {
                if ( confirm("Are you sure you want to delete this project write-in?")) {
                    axios.post('/deleteProjectWriteIn', {
                        csrfToken: this.csrfToken,
                        writeInId: this.projectWriteInId
                    }).then(response => {
                        if ( response.data.success ){
                            this.showStatus = true;
                            this.status = 'success';
                            this.statusMessage = response.data.msg;
                            window.location.reload();
                        }else{
                            this.showStatus = true;
                            this.status = 'error';
                            this.statusMessage = response.data.msg;
                        }
                    }).catch(error => {
                        this.loaded = true;
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors || {};
                        }
                    });
                }
            }
        },
    }
</script>