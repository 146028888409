<template>
    <div>
        <alert-popup v-if="success" alert-type="success" alert-message="Attendance recorded!"></alert-popup>
        <v-icon v-if="attendanceStatus < 4" v-model="attendanceStatus" @click="changeStatus">{{ icon }}</v-icon>
        <div v-if="attendanceStatus === '4'"><a v-bind:href="'/class/'+ makeupClassId"><v-icon>{{ makeUpIcon }}</v-icon></a></div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        props: ['attendanceId', 'currentStatus'],
        data() {
            return {
                attendanceStatus: this.currentStatus,
                icon: null,
                makeupClassId: 0,
                makeupAttended: false,
                makeUpIcon: '',
                errors: {},
                success: false,
                loaded: true,
                csrfToken: null,
            }
        },
        mounted() {
            this.csrfToken = document.querySelector('meta[name="csrf-token"]').content;
            if ( this.attendanceStatus === '0' ){ // No status
                this.icon = "radio_button_unchecked";
            }else if ( this.attendanceStatus === '1' ){ // Attended
                this.icon = "check_circle";
            }else if ( this.attendanceStatus === '2' ){ // Missed
                this.icon = "cancel";
            }else if ( this.attendanceStatus === '3' ){ // Not Attending
                this.icon = "lens";
            }else if ( this.attendanceStatus === '4' ){ // Make Up Scheduled / Attended
                axios.post('/getMakeUpClass', {
                    csrfToken: this.csrfToken,
                    attendanceId: this.attendanceId
                }).then(response => {
                    this.makeupClassId = response.data.id;
                    console.log(response.data.attendanceStatus);
                    if ( response.data.attendanceStatus === 0 ){
                        this.makeUpIcon = "arrow_forward";
                    }else{
                        this.makeUpIcon = "done_all";
                    }
                }).catch(error => {
                    this.loaded = true;
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors || {};
                    }
                });
            }
        },
        methods: {
            changeStatus() {
                if (this.loaded) {
                    if (this.attendanceStatus < 3) {
                        this.attendanceStatus++;
                    } else {
                        this.attendanceStatus = 0;
                    }

                    if (this.attendanceStatus === 0) { // No status
                        this.icon = "radio_button_unchecked";
                    } else if (this.attendanceStatus === 1) { // Attended
                        this.icon = "check_circle";
                    } else if (this.attendanceStatus === 2) { // Missed
                        this.icon = "cancel";
                    } else if (this.attendanceStatus === 3) { // Not Attending
                        this.icon = "lens";
                    }

                    this.toggleAttended();
                }
            },
            toggleAttended() {
                if (this.loaded) {
                    this.loaded = false;
                    this.success = false;
                    this.errors = {};
                    axios.post('/markStudentAttended', {
                        csrfToken: this.csrfToken,
                        attendanceStatus: this.attendanceStatus,
                        attendanceId: this.attendanceId
                    }).then(response => {
                        this.loaded = true;
                        this.success = true;
                    }).catch(error => {
                        this.loaded = true;
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors || {};
                        }
                    });
                }
            }
        },
    }
</script>