<template>
    <div>
        <alert-popup v-if="success" alert-type="success" v-bind:alert-message="successMessage"></alert-popup>
        <div class="row">
            <div class="col-md-11">
                {{ note }}
                <span class="font-italic small">{{ noteDate | moment('MM/DD/YYYY') }}</span>
            </div>
            <div class="col-md-1">
                <v-icon class="text-danger" role="button" @click="deleteNote()">delete</v-icon>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';


    export default {
        props: ['note','noteDate','noteId'],
        data() {
            return {
                errors: {},
                success: false,
                successMessage: '',
                loaded: true,
                csrfToken: null,
            }
        },
        mounted() {
            this.csrfToken = document.querySelector('meta[name="csrf-token"]').content;
        },
        methods: {
            deleteNote() {
                if ( confirm("Are you sure you want to delete this note?")) {
                    axios.post('/deleteNote', {
                        noteId: this.noteId
                    }).then(response => {
                        this.success = response.data.success;
                        this.successMessage = response.data.successMessage;
                        window.location.reload();
                    }).catch(error => {
                        this.loaded = true;
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors || {};
                        }
                    });
                }
            }
        },
    }
</script>