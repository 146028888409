<template>
    <div>
        <alert-popup v-if="editSuccess" alert-type="success" alert-message="User edited!"></alert-popup>

        <v-container ma-0 pa-0 grid-list-md text-xs-center fill-height>
            <v-layout row wrap>
                <div v-if="errors && errors.submit" class="alert alert-danger">{{ errors.submit }}</div>
                <label>Name *</label>
                <v-flex xs12 sm6 md12>
                    <v-text-field
                            placeholder="User's name"
                            solo
                            v-model="name"
                    ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md12>
                    <div v-if="errors && errors.name" class="alert alert-danger">{{ errors.name }}</div>
                </v-flex>

                <label>Email *</label>
                <v-flex xs12 sm6 md12>
                    <v-text-field
                            placeholder="User's email"
                            solo
                            v-model="email"
                    ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md12>
                    <div v-if="errors && errors.email" class="alert alert-danger">{{ errors.email }}</div>
                </v-flex>

                <label>Password</label>
                <v-flex xs12 sm6 md12>
                    <v-text-field
                            v-model="password"
                            :append-icon="show1 ? 'visibility' : 'visibility_off'"
                            :rules="[rules.required, rules.min]"
                            :type="show1 ? 'text' : 'password'"
                            label="Password"
                            hint="At least 8 characters"
                            counter
                            solo
                            @click:append="show1 = !show1"
                    ></v-text-field>
                </v-flex>

                <label>Confirm Password</label>
                <v-flex xs12 sm6 md12>
                    <v-text-field
                            v-model="password2"
                            :append-icon="show2 ? 'visibility' : 'visibility_off'"
                            :rules="[rules.required, rules.min, rules.passMatch]"
                            :type="show2 ? 'text' : 'password'"
                            label="Confirm Password"
                            hint="At least 8 characters"
                            counter
                            solo
                            @click:append="show2 = !show2"
                    ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md12>
                    <div v-if="errors && errors.password" class="alert alert-danger">{{ errors.password }}</div>
                </v-flex>

                <label>Permissions</label>
                <v-flex xs6 md12>
                    <v-select
                            v-model="userPermissions"
                            :items="permissions"
                            item-text="label"
                            item-value="value"
                            required
                            placeholder="Select Permissions"
                            solo
                    ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md12>
                    <div v-if="errors && errors.permissions" class="alert alert-danger">{{ errors.permissions }}</div>
                </v-flex>

                <label>Location</label>
                <v-flex xs6 md12>
                    <v-select
                            v-model="locationId"
                            :items="locations"
                            item-text="name"
                            item-value="id"
                            required
                            placeholder="Select Location"
                            solo
                    ></v-select>
                </v-flex>

                <button class="btn btn-block btn-primary" @click="editUser()">Edit User</button>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    import axios from 'axios';


    export default {
        props: ['userId'],
        data() {
            return {
                name: null,
                email: null,
                password: null,
                password2: null,
                userPermissions: [],
                show1: false,
                show2: false,
                permissions: {},
                locations: [],
                locationId: 0,
                errors: {},
                editSuccess: false,
                loaded: true,
                rules: {
                    required: value => !!value || 'Required.',
                    min: v => v.length >= 8 || 'Min 8 characters',
                    passMatch: v => v === this.password || 'Passwords must match'
                },
            }
        },
        mounted() {
            this.getUserDetails();
            this.getLocations();

            this.permissions = ['Admin','Manager','Teacher'];
        },
        methods: {
            getUserDetails() {
                axios.post('/getUserDetails', {
                    userId: this.userId
                }).then(response => {
                    this.loaded = true;
                    this.name = response.data.name;
                    this.email = response.data.email;
                    this.userPermissions = response.data.permissions;
                    this.locationId = response.data.locationId;
                }).catch(error => {
                    this.loaded = true;
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors || {};
                    }
                });
            },
            getLocations() {
                axios.post('/getAllLocations', {
                }).then(response => {
                    this.locations = response.data;
                    this.locations.unshift({'id':0,'name':'No location (Admin/Manager)'});
                }).catch(error => {
                    this.loaded = true;
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors || {};
                    }
                });
            },
            editUser() {
                if (this.loaded) {
                    this.loaded = false;
                    this.editSuccess = false;
                    this.errors = [];
                    if (!this.name) {
                        this.errors.name = 'Name required.';
                    }else if (!this.email) {
                        this.errors.email = 'Email required.';
                    }else if (!this.userPermissions) {
                        this.errors.permissions = 'Permissions required.';
                    }else if (this.password !== this.password2){
                        this.errors.password = 'Passwords do not match.';
                    }else{
                        axios.post('/editUser', {
                            csrfToken: this.csrfToken,
                            userId: this.userId,
                            name: this.name,
                            email: this.email,
                            password: this.password,
                            password2: this.password2,
                            permissions: this.userPermissions,
                            locationId: this.locationId,
                        }).then(response => {
                            this.fields = {}; //Clear input fields.
                            this.loaded = true;
                            if (response.data.success){
                                this.editSuccess = true;
                            }else{
                                this.errors.submit = response.data.message;
                            }
                        }).catch(error => {
                            this.loaded = true;
                            if (error.response.status === 422) {
                                this.errors = error.response.data.errors || {};
                            }
                        });
                    }
                }
            }
        }
    }
</script>